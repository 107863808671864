/* eslint-disable arrow-body-style */

import { Action } from 'redux';
import { SurveyMultiAppsService } from 'src/services';
import { GetState, DispatchFunc } from 'src/reducers';
import { isType } from 'src/reducers/surveys-multi-apps/surveys-multi-apps.utils';
import { getCurrentOrgId } from 'src/selectors/current_app';
import {
  getSurveyById,
  getSurveysMultiAppsIsLoading,
  getSurveysMultiAppsIsBootstrapped,
} from 'src/selectors/surveys-multi-apps.selectors';

import { LoveDialogActions } from 'src/actions/love-dialog/love-dialog.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import { AppInteractionData, MultiAppsSurvey, SurveyResponseStat, SurveyTextAnswers } from 'src/reducers/surveys-multi-apps';

import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.models';

import { deleteSurvey, fetchSurveys, FetchSurveysConfigEnum, updateEmailSummaryAppIds } from '../surveys-multi-apps';

import { SurveyMultiAppsActions } from './survey-multi-apps.action-types';

interface SurveyMultiAppsActionPayload {
  ids?: string[];
  id?: string;
  surveyId?: string;
  textAnswers?: Record<string, SurveyTextAnswers>;
  stats?: SurveyResponseStat[];
  surveysActive?: MultiAppsSurvey[];
  surveysNotActive?: MultiAppsSurvey[];
  interactionData?: AppInteractionData[];
  survey?: MultiAppsSurvey;
}

export type SurveyReducerActions = Action<
  | SurveyMultiAppsActions
  | LoveDialogActions.SAVE_LOVE_DIALOG_SUCCESS
  | typeof UPDATE_CURRENT_APP_PENDING
  | typeof UPDATE_CURRENT_APP_SUCCESS
> & {
  payload?: SurveyMultiAppsActionPayload;
  surveys?: MultiAppsSurvey[];
  surveyId?: string;
  emailSummaryAppIds?: string[];
  active?: boolean;
  isFetching?: boolean;
  error?: Error;
  page?: number;
  total?: number;
};

type SurveysDispatch = DispatchFunc<SurveyReducerActions>;

export const surveys = {
  deleteSurvey,
  fetchSurveys,
  updateEmailSummaryAppIds,

  bootstrapSurveys: () => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const state = getState();
      const isLoading = getSurveysMultiAppsIsLoading(state);
      const isBootstrapped = getSurveysMultiAppsIsBootstrapped(state);

      dispatch({ type: SurveyMultiAppsActions.BOOTSTRAP_SURVEYS_MULTI_APPS, payload: {} });

      if (!isLoading && !isBootstrapped) {
        return dispatch(surveys.fetchSurveys(1, FetchSurveysConfigEnum.ALL));
      }
      return Promise.resolve();
    };
  },

  fetchSurveysByIds: (surveyIds: string[]) => {
    return (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      dispatch({ type: SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_PENDING, payload: { ids: surveyIds } });

      const promises: Promise<MultiAppsSurvey>[] = [];
      surveyIds.forEach((id) => {
        const promise = new Promise((res) => res(SurveyMultiAppsService.fetchSurvey(orgId, id)));
        promises.push(promise as Promise<MultiAppsSurvey>);
      });

      return Promise.all(promises)
        .then((resp) =>
          dispatch({
            type: SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_SUCCESS,
            payload: resp.map((item) =>
              SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave(SurveyMultiAppsModel.oldSurveyToNew(item)),
            ),
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.FETCH_SURVEYS_MULTI_APPS_BY_ID_ERROR,
            payload: { ids: surveyIds },
            error,
          }),
        );
    };
  },

  fetchSurvey: (surveyId: string) => {
    return (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());
      dispatch({ type: SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_PENDING, payload: { id: surveyId } });

      return SurveyMultiAppsService.fetchSurvey(orgId, surveyId)
        .then((survey) =>
          dispatch({
            type: SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_SUCCESS,
            payload: SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave(SurveyMultiAppsModel.oldSurveyToNew(survey)),
          }),
        )
        .catch((error) =>
          dispatch({ type: SurveyMultiAppsActions.FETCH_SURVEY_MULTI_APPS_ERROR, payload: { id: surveyId }, error }),
        );
    };
  },

  fetchStats: (surveyId: string) => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(getState());
      const survey = getSurveyById(state, surveyId);
      dispatch({ type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_PENDING, payload: { surveyId } });

      const questions = survey?.questionSets.map((item) => item.questions[0]);
      const inialStates = questions?.map((q) => ({
        id: q.id,
        answers: (q.answer_choices || []).map((ans) => ({
          choice: ans.value,
          count: 0,
        })),
      }));

      return SurveyMultiAppsService.getSurveyStats(orgId, surveyId)
        .then((stats) => {
          const newStats = stats.map((ans) => {
            if (ans.answer_count === 0 && isType.Multichoice(ans)) {
              const resAns = inialStates?.find((elem) => elem.id === ans.id);
              return { ...ans, answers: resAns?.answers };
            }
            return ans;
          });
          return dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_SUCCESS,
            payload: { surveyId, stats: newStats as SurveyResponseStat[] },
          });
        })
        .catch((error) =>
          dispatch({ type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_STATS_ERROR, payload: { surveyId }, error }),
        );
    };
  },

  fetchTextAnswers: (surveyId: string, pagination?: { limit: number; page: number }) => {
    return (dispatch: SurveysDispatch, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);
      const survey = getSurveyById(state, surveyId);

      const questions = survey
        ? survey.questionSets
            .map((item) => item.questions[0])
            .filter((item) => isType.Singleline(item) || isType.Multichoice(item) || isType.Multiselect(item)) // TODO: Check another types if needed!
            .filter(
              (item) =>
                (item.answer_choices && SurveyMultiAppsModel.isQuestionWithOtherChoice(item)) ||
                isType.Singleline(item),
            )
            .map((item) => item.id as string)
        : [];

      dispatch({ type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_PENDING, payload: { surveyId } });

      return SurveyMultiAppsService.getTextAnswers(orgId, surveyId, questions, pagination)
        .then((textAnswers) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_SUCCESS,
            payload: { surveyId, textAnswers },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_TEXT_ANSWERS_ERROR,
            payload: { surveyId },
            error,
          }),
        );
    };
  },

  fetchQuestionTextAnswers: (surveyId: string, questionId: string, pagination?: { limit: number; page: number }) => {
    return (dispatch: Function, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);

      dispatch({
        type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_PENDING,
        payload: { surveyId, questionId },
      });

      return SurveyMultiAppsService.getTextAnswers(orgId, surveyId, [questionId], pagination)
        .then((textAnswers) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_SUCCESS,
            payload: { surveyId, textAnswers, questionId },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.GET_SURVEY_MULTI_APPS_QUESTION_TEXT_ANSWERS_ERROR,
            payload: { surveyId, questionId },
            error,
          }),
        );
    };
  },

  save: (item: MultiAppsSurvey, apiVersion: string) => {
    return (dispatch: Function, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      if (!item.id) {
        const questionSets = SurveyMultiAppsModel.enrichQSetsWithDefaultSkipLogic(item.questionSets);
        const json = SurveyMultiAppsModel.toLegacy(
          SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave({ ...item, questionSets }),
        );
        dispatch({ type: SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_PENDING, payload: item });
        return SurveyMultiAppsService.create(orgId, json as unknown as MultiAppsSurvey, apiVersion)
          .then((survey) =>
            dispatch({
              type: SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_SUCCESS,
              payload: SurveyMultiAppsModel.oldSurveyToNew(survey),
            }),
          )
          .catch((error) =>
            dispatch({ type: SurveyMultiAppsActions.CREATE_SURVEY_MULTI_APPS_ERROR, payload: item, error }),
          );
      }

      const questionSets = SurveyMultiAppsModel.updQSetsWithDefaultSkipLogic(item.questionSets);
      const json = SurveyMultiAppsModel.toLegacy(
        SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave({ ...item, questionSets }),
      );
      dispatch({ type: SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_PENDING, payload: { surveyId: item.id } });
      return SurveyMultiAppsService.update(orgId, json as unknown as MultiAppsSurvey, apiVersion)
        .then((survey) =>
          dispatch({
            type: SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_SUCCESS,
            payload: SurveyMultiAppsModel.oldSurveyToNew(survey),
          }),
        )
        .catch((error) =>
          dispatch({ type: SurveyMultiAppsActions.UPDATE_SURVEY_MULTI_APPS_ERROR, payload: item, error }),
        );
    };
  },

  updateSurveyActive: (surveyId: string, interactionData: AppInteractionData[], apiVersion: string) => {
    return (dispatch: Function, getState: GetState) => {
      const state = getState();
      const orgId = getCurrentOrgId(state);
      const item = { ...getSurveyById(state, surveyId), interactionData } as MultiAppsSurvey;

      dispatch({
        type: SurveyMultiAppsActions.REQUEST_UPDATE_SURVEY_MULTI_APPS_ACTIVE,
        surveyId,
        active: false,
      });

      const questionSets = SurveyMultiAppsModel.updQSetsWithDefaultSkipLogic(item.questionSets);
      const json = SurveyMultiAppsModel.toLegacy(
        SurveyMultiAppsModel.getParsedHtmlFieldsBeforeSave({ ...item, questionSets }),
      );

      return SurveyMultiAppsService.update(orgId, json as unknown as MultiAppsSurvey, apiVersion)
        .then(() =>
          dispatch({
            type: SurveyMultiAppsActions.RECEIVE_UPDATE_SURVEY_MULTI_APPS_ACTIVE,
            surveyId,
            active: false,
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.ERRORED_UPDATE_SURVEY_MULTI_APPS_ACTIVE,
            surveyId,
            error,
          }),
        );
    };
  },

  cloneSurveyToApp: (surveyId: string, appId: string, options: { cloneTargeting: boolean }) => {
    return (dispatch: Function) => {
      dispatch({ type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_APP_PENDING, payload: { appId, surveyId } });
      return SurveyMultiAppsService.cloneSurveyToApp(appId, surveyId, options)
        .then((data) =>
          dispatch({
            type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_APP_SUCCESS,
            payload: { appId, surveyId, survey: data.survey },
          }),
        )
        .catch((error) =>
          dispatch({
            type: SurveyMultiAppsActions.CLONE_SURVEY_MULTI_APPS_TO_APP_ERROR,
            payload: { appId, surveyId },
            error,
          }),
        );
    };
  },
};
